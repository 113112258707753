@import "../../variables";

.skillcard {
  // border: 1px solid #f7f7f7;
  padding: 5px;
  background-color: $charcoal1;
  a {
    color: $gray2;
  }
  transition: all 500ms;
  //   box-shadow: 0 0 10px 5px rgba(253, 248, 247, 69%);
}
.skillcard:hover {
  background-color: $gray2;
  color: $charcoal1;

  a {
    color: $charcoal1;
  }
}
