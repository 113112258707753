@import "../../variables";

.form-error {
  color: $redhilight;
}

.submit-load,
.submit-success,
.submit-error {
  margin-top: 20px;
}

.submit-success {
  color: $greenhilight;
}
.submit-error {
  color: $redhilight;
}
.btn {
  margin-top: 20px;
}
label {
  font-family: $headerfont;
  font-size: 2em;
}
.form-control {
  background-color: $gray2;
  color: $charcoal1;
}
