@import "../../variables";

.footer {
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px $charcoal2;
}

.footer-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  height: 100%;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  height: 100%;
}

.footer-creds {
  @include hyperlink;
  a {
    transition: all 300ms;
    color: $gray2;
  }
}

.footer-socials {
  display: flex;
  justify-content: space-around;
  font-size: 1.5em;

  a {
    transition: all 300ms;
    color: $gray2;
    margin: 0 10px;
    &:hover {
      color: $bluehilight;
      transform: scale(1.1);
    }
  }
}
