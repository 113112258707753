// theme colors
$charcoal1: #1d1f21;
$charcoal2: #313538;
$gray1: #a5a5a6;
$gray2: #f5f5f5;
$charcoal1op: rgba(#1d1f21, 0.9);

$greenhilight: #26ffae;
$bluehilight: #1da1f2;
$redhilight: #ff3333;

//fonts
$headerfont: "Bebas Neue", cursive;
$monofont: "Anonymous Pro", monospace;

// mixins
@mixin hyperlink {
  position: relative;
  text-decoration: none;
  font-weight: 400;
  color: inherit;
  transition: all 0.35s ease;

  &::before {
    content: "";
    position: absolute;
    width: 0%;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: $bluehilight;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover {
    color: inherit;

    &::before {
      width: 100%;
    }
  }
}
