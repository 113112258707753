@import "../../variables";

.contact {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  // border-top: solid 1px $charcoal2;

  a {
    color: $bluehilight;
    &:hover {
      color: $bluehilight;
    }
  }
}

.contact-inner {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2rem;

  .contact-body {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.hyper {
  @include hyperlink;
}
