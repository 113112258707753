@import "../../variables";

.about {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: -2;
  // border-top: solid 1px $charcoal2;
}
.about-inner {
  background-color: $charcoal2;
  color: $gray2;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2rem;
  border-radius: 0.25rem;
}

.about-copy {
  text-align: left;
  font-size: 1.2em;
}
.center-text {
  margin-top: 30px;
}
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 20px;
  z-index: 2;
  flex-direction: column;
}
.profile-pic {
  position: relative;
  max-width: 300px;
  margin: 0 auto;

  .wrapper ::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;
    border: 2px solid $gray2;
    top: 20px;
    left: 20px;
    z-index: -1;
    transition: transform 300ms;
  }
  &:hover ::after {
    transform: translate(-5px, -5px);
  }
}
ul {
  padding: 0;
  list-style-type: none;
  li {
    margin-bottom: 10px;
  }
}

.socials {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 3em;

  a {
    margin: 25px;
    transition: all 300ms;
    color: $gray2;
    &:hover {
      color: $bluehilight;
      transform: scale(1.1);
    }
  }
}

@media (max-width: 767.98px) {
  .about-copy {
    text-align: center;
  }
}
