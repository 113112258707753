@import "../../variables";

.projects {
  .title {
    font-family: $headerfont;
    font-size: 2em;
  }
  .text-link {
    @include hyperlink;
    font-family: $headerfont;
    font-size: 2em;
    margin: 0 20px;
    color: $bluehilight;
    &:hover {
      color: $bluehilight;
    }
  }
}

.card-text {
  margin: 1rem 0;
}
