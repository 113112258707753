@import "../../variables";
header {
  z-index: 600;
  width: 100%;
  font-family: "Bebas Neue", cursive;
  font-size: 1.5rem;
  // margin-bottom: auto;
  position: absolute;
}

.navbar {
  padding: 30px 0;
  transition: background-color 0.25s linear;
}
.container {
  max-width: 1140px;
}
.navbar-brand {
  font-size: 2rem;
}
.nav-num {
  font-size: 0.7em;
  color: $bluehilight;
}

.navbar-dark .navbar-nav .nav-link {
  color: $gray2;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: $gray2;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: $gray2;
}
.navbar-dark .navbar-nav .nav-link.active:hover {
  color: $gray2;
}

.nav-section {
  @include hyperlink;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active {
  border: none;
  color: $gray1;
  box-shadow: none;
}
.navbar-dark .navbar-toggler {
  color: $gray2;
}

.open {
  background-color: $charcoal1op;
}
