@import "../../variables";

.work {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  background-color: $charcoal1;
  color: $gray2;
  // border-top: solid 1px $charcoal2;
}
.work-inner {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2rem;
}
.work-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: $charcoal1;
}
.work-header {
  margin-bottom: 2em;
}
.project {
  flex: 1 0 50%;
}
