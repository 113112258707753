@import "../../variables";

.skills {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // border-top: solid 1px $charcoal2;
}

.skills-inner {
  background-color: $charcoal2;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 2rem;
  border-radius: 0.25rem;
}
.skills-header {
  margin-bottom: 2em;
}
.skills-sub {
  margin-top: 20px;
  margin-bottom: 20px;
}
.horizontal-divide {
  border-top: $gray1 solid 2px;
  width: 50%;
  margin: 60px auto;
}
