@import "custom";
@import "variables";
* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: "Lato", sans-serif;
  background-color: $charcoal1;
  color: $gray2;

  h1 {
    font-family: "Bebas Neue", cursive;
    font-size: 4em;
    color: $gray2;
  }
  h4 {
    font-family: "Bebas Neue", cursive;
    font-size: 2.5em;
  }
  a {
    text-decoration: none;

    background-color: transparent;
  }
  a:hover {
    text-decoration: none;
  }
  .sec-num {
    font-size: 0.75em;
    color: $bluehilight;
    margin-right: 2px;
  }
  
}

@media (max-width: 575.98px) {
  .side-nav {
    visibility: hidden;
  }
  .home-body {
    text-align: center;
  }
}
