@import "../../variables";

.home {
  height: 100vh;
  // position: relative;
}

.home-landing {
  display: flex;
  align-items: center;
  height: 100%;
}
.home-body {
  text-align: left;
  color: $gray2;
}

.greeting {
  font-size: 3em;
}
.big-heading {
  font-family: "Bebas Neue", cursive;
  font-size: 10em;
}

.sub-heading {
  font-size: 3em;
  margin-top: -40px;
  display: inline-block;
}
.curly {
  color: $bluehilight;
  font-family: $monofont;
}
.chontainer {
  display: inline-block;
}
.mobile-line-break {
  display: none;
}

.description {
  font-family: $monofont;
  background-color: $charcoal2;
  color: $gray2;
  padding: 0 5px;
  transition: all ease 150ms;
}
.description:hover {
  background-color: $gray2;
  color: $charcoal2;
}

.side-nav {
  z-index: 999;
  display: flex;

  position: fixed;
  margin-top: -220px;
  right: 4em;
  width: 40px;
  ul {
    width: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding: 0;
    list-style-type: none;
  }
  ul::before,
  ul::after {
    content: "";
    width: 1px;
    height: 40px;
    margin-bottom: 8px;
    background-color: $gray1;
  }
  .side-link {
    display: flex;
    width: inherit;
    cursor: pointer;
    min-height: 2em;
    position: relative;
    color: $gray1;
    transition-delay: 100ms;
    outline-color: transparent;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: button;
    border-radius: 0;
    border: none;
    text-transform: none;
    overflow: visible;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
  }
  .side-link::after {
    content: "";
    width: 100%;
    height: 0.523em;
    position: absolute;

    transition: transform 0.2s 100ms;
  }
  .side-link:hover {
    transform: scale(1.5);
    transition: all 200ms;
    color: $bluehilight;
  }
}

@media (max-width: 1399.98px) {
  .side-nav {
    visibility: hidden;
  }
}

@media (max-width: 991.98px) {
  .mobile-line-break {
    display: block;
  }
  .home-landing {
    justify-content: center;
  }
  .home-body {
    text-align: center;
    font-size: 80%;
  }
  .big-heading {
    margin-top: 20px;
    line-height: 0.9em;
  }
  .sub-heading {
    font-size: 2.5em;
    margin-top: 10px;
  }
}

@media (max-width: 575.98px) {
  .home-body {
    text-align: center;
    font-size: 70%;
  }
  .big-heading {
    line-height: 0.9em;
  }
  .sub-heading {
    font-size: 2.5em;
    display: block;
  }
}
