// Override default variables before the import

@import "./variables";
$theme-colors: (
  "primary": $gray2,
  "secondary": $gray2,
  "success": #76c1d4,
  "gray": #f7f7f7,
  "bs-red": #ff3333,
);
// $font-family-monospace: "Anonymous Pro", monospace;

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  body {
    color: green;
  }
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
